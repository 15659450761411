.Header{
    position: fixed;
    top: 0px;
    bottom: auto;
    width: 100%;
    z-index: 10;
    height: 75px;
    padding: 5px 0px;
}

.Collapsible{
    position: fixed;
    top:75px;
    bottom: auto;
    width: 250px;
    display: flex;
    flex-direction: column;
    right: 0px;
    padding: 10px;
    background-color: #FCF2DB;
    border-radius: 0px 0px 5px 5px;
}


.LanguageArea{
    position: fixed;
    top: 60px;
    right: 0px;
    width: 211px;
    display: flex;
    flex-direction: column;
    margin-right: 5vw;
    text-align: center;
    background-color: #685044;
    border-radius: 0px 0px 5px 5px;
}

@media only screen and (max-width:907px){
    button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-disableElevation{
        width: 100%;
    }
}