.Body{
    padding-top: 110px;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-image: url('../../img/green-dust-and-scratches.png');
    width: 100%;
}

.LongTextContainer{
    background-color: rgba(255, 255, 255, 0.432);
    margin: 0px 15vw;
    padding: 10vh 5vw;
    min-height: 100vh;
}

.LongTextContent{
    text-align: left;
}

.ContentText{
    width: 50%;
}

.ImageContainer{
    width: 50%;
}

.Footer a{
    text-decoration: none;
    color: #FCF2DB;
  }
  
.Footer a:visited{
    text-decoration: none;
    color: #FCF2DB;
  }

@media only screen and (max-width:649px){
    .Hero, .Overview, .CTA{
        flex-direction: column-reverse;
    }
    .ContentText{
        width: 100%;
    }
    
    .ImageContainer{
        width: 100%;
        margin-bottom: 20px;
    }
    .MuiTypography-root{
        width: 100%;
    }
    .CTA .MuiTypography-root {
        text-align: center;
    }

    .CTA .ContentText{
        width: 100%;
    }
    .LongTextContainer{
        background-color: rgba(255, 255, 255, 0.432);
        margin: 0px 5vw;
        padding: 10vh 1vw;
        min-height: 100vh;
    }

    .MuiTypography-h1{
        font-size: 60px !important;
    }
}
