.CTA{
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: row-reverse;
    padding: 5vh 5vw;
    margin-bottom: 10vh;
}



.CTA .ContentText{
    width: 50%;
}

.CTA .MuiTypography-body1{
    text-align: left;
}

