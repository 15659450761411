.Footer{
    background-color: #685044;
    padding: 3vh 5vw ;
    display: flex;
}

.FooterGeneralInfo{
    margin-right: 50px;
}

.FooterNavigation{
    text-align: right;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.FooterNavigation .Box{
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
}

.Footer .MuiTypography-subtitle2 {
    color:  #FCF2DB;
}

@media only screen and (max-width:649px){
    .Footer{
        flex-direction: column;
    }
}