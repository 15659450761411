

.MuiButton-containedSecondary.CTA1{
    margin-right: 10px;
}

.ContentHeader{
    background-color: #685044;
    padding: 3vh;
    margin-bottom: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.ContentHeader .MuiTypography-root{
    color: #FCF2DB;
    display: flex;
    align-items: center;
}

